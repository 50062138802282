.list-labels {
    padding: $base * 2 0;
}

.list-item {
    padding: $base * 0.75 $base;
    // border-bottom: 2px solid $c-border;
    background-color: $c-white;
    &:last-child {
        border-bottom: none;
    }
}

.list-dnd {
    min-height: 60vh;
    // margin-right: $base;
    // // padding-left: $base;
    // // border-right: 2px solid $c-border;
    // &:last-child {
    //     // border-left: 2px solid $c-border;
    //     padding-right: 0;
    // }
}
