.notification-wrap {
    position: fixed;
    right: 0;
    bottom: $base * 2;
    z-index: 20;
}

.notification {
    display: inline-flex;
}
