.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $c-white;
    @include mq(laptop) {
        background-color: rgba(#26374d, 0.9);
    }
}

.modal-nav {
    height: $base * 4.5;
    position: relative;
    z-index: 10;
    // top: 0;
    // left: 0;
    // right: 0;
    // background-color: $c-white;
}

.modal-center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-scroll {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: $base * 6;
    padding-bottom: $base * 6;
}

.modal-planning {
    width: 100%;
    display: flex;
    padding-top: 0;
    padding-bottom: $base * 6;
}

.modal-slide {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-select {
    max-height: $base * 20;
    min-height: $base * 15;
    // border: 2px solid $c-border;
    // border-radius: $base * 0.5;
    // background-color: $c-white;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: $base;
    }
    &::-webkit-scrollbar-track {
        border-radius: $base * 0.5;
        background-color: rgba($c-main, 0.15);
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba($c-main, 1);
        border-radius: $base * 0.5;
    }
}

.modal-container {
    position: relative;
    width: 100%;
    padding-left: $base * 1;
    padding-right: $base * 1;
    @include mq(laptop) {
        padding: $base * 2;
        border-radius: $base * 0.5;
        background-color: $c-white;
        border: 1px solid $c-border;
        box-shadow: $shadow-secondary;
        width: $base * 40;
    }
}

.modal-container-m {
    max-width: $base * 45;
    width: 100%;
}

.modal-container-l {
    max-width: $base * 55;
    width: 100%;
}

.modal-planning-container {
    position: relative;
    width: 100%;
    padding-left: $base * 1;
    padding-right: $base * 1;
    @include mq(laptop) {
        padding: $base * 2;
        border-radius: $base * 0.5;
        background-color: $c-white;
        border: 1px solid $c-border;
        box-shadow: $shadow-secondary;
        width: $base * 55;
    }
}

.modal-planning-flex {
    display: block;
    @include mq(laptop) {
        display: flex;
        justify-content: space-between;
    }
}
// .modal-slide {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100vw;
//     height: 100vh;
// }

// .modal-slide-container {
//     position: relative;
//     z-index: 10;
//     width: $base * 40;
//     padding: $base * 2;
//     border-radius: $base * 0.5;
//     background-color: $c-white;
//     border: 1px solid $c-border;
//     box-shadow: $shadow-secondary;
// }

// // .modal-container-wrap {
// //     display: flex;
// //     justify-content: center;
// //     padding-top: $base * 6;
// //     padding-bottom: $base * 6;
// // }

// .modal-container {
//     position: relative;
//     width: $base * 55;
//     border-radius: $base * 0.5;
//     background-color: $c-white;
//     border: 1px solid $c-border;
//     box-shadow: $shadow-secondary;
// }

// .modal-event-handler {
//     // position: absolute;
//     // top: 0;
//     // left: 0;
//     // right: 0;
//     // height: 100%;
// }

// // .modal-list-item {
// //     border-bottom: 2px solid $c-border;
// //     transition: 300ms background-color $ease-card;

// //     &:hover {
// //         background-color: rgba($c-cloud, 0.4);
// //     }
// //     &--active {
// //         background-color: rgba($c-main, 0.3);
// //     }
// // }
