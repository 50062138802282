.container {
    width: 100%;
    padding-left: $base * 1;
    padding-right: $base * 1;
    @include mq(tablet) {
        padding-left: $base * 2;
        padding-right: $base * 2;
    }
    @include mq(laptop) {
        padding-left: $base * 4;
        padding-right: $base * 4;
    }
    @include mq(desktop) {
        padding-left: $base * 1;
        padding-right: $base * 1;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }
}

.container-m {
    width: $base * 40;
}
