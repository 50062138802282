.mb-1 {
    margin-bottom: $base * 0.25;
}

.mb-2 {
    margin-bottom: $base * 0.5;
}

.mb-3 {
    margin-bottom: $base * 1;
}

.mb-4 {
    margin-bottom: $base * 1.5;
}

.mb-5 {
    margin-bottom: $base * 2;
}

.mb-6 {
    margin-bottom: $base * 3;
}

.mt-1 {
    margin-top: $base * 0.25;
}

.mt-2 {
    margin-top: $base * 0.5;
}

.mt-3 {
    margin-top: $base * 1;
}

.mt-4 {
    margin-top: $base * 1.5;
}

.mt-5 {
    margin-top: $base * 2;
}

.mr-1 {
    margin-right: $base * 0.25;
}

.mr-2 {
    margin-right: $base * 0.5;
}

.mr-3 {
    margin-right: $base * 1;
}

.mr-4 {
    margin-right: $base * 1.5;
}

.mr-5 {
    margin-right: $base * 2;
}

.ml-1 {
    margin-left: $base * 0.25;
}

.ml-2 {
    margin-left: $base * 0.5;
}

.ml-3 {
    margin-left: $base * 1;
}

.ml-4 {
    margin-left: $base * 1.5;
}

.ml-5 {
    margin-left: $base * 2;
}

.auto {
    margin: auto;
}
