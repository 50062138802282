.uploader {
    border: 2px dashed $c-cloud;
    color: $c-silver;
    border-radius: $base * 0.5;
    padding: $base * 2 $base * 2;
    &--success {
        border: 2px dashed $c-main;
        color: $c-main;
    }
}

/*
{file && (
                                <div className="p-box flex flex-center mt-m br b-dotted">
                                    <a
                                        href={this.state.downloadURL}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="fs-s"
                                    >
                                        {this.state.file.name}
                                    </a>
                                </div>
                            )}
*/
