.items-center {
    align-items: center;
}

.self-center {
    align-self: center;
}

.place-self-center {
    place-self: center;
}
