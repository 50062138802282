.light {
    display: inline-block;
    height: $base * 1;
    width: $base * 1;
    border-radius: 50%;
    background-color: $c-border;
    border: 2px solid darken($c-border, 10%);
}

.light-on {
    background-color: $c-green;
    border: 2px solid darken($c-green, 10%);
}

.light-off {
    background-color: lighten($c-red, 10);
    border: 2px solid darken($c-red, 0%);
}

.light-main {
    background-color: $c-main;
    border: 2px solid darken($c-main, 10%);
}

.light-slide {
    position: relative;
    display: inline-block;
    height: $base * 1.5;
    width: $base * 1.5;
    background-color: $c-white;
    border: 2px solid $c-gray-d;
    will-change: transform;
    transition: transform 250ms $ease-scale;
}

.light-slide-on {
    border: $base * 0.2 solid lighten($c-main, 10%);
    background-color: $c-main;
    transform: scale(1.3);
}

.light-nav {
    height: $base * 1;
    width: $base * 1;
    border-radius: $base * 0.3;
    border: 2px solid $c-white;
    // border-top: 2px solid $c-white;

    background-color: lighten($c-main, 0%);
}
// .dot {
//     position: relative;
//     display: inline-block;
//     height: $base * 1.5;
//     width: $base * 1.5;
//     border-radius: 50%;
//     background-color: $c-silver;
//     will-change: transform;
//     transition: transform 250ms $ease-scale;
//     margin-right: $base * 0.5;
//     &--active {
//         border: $base * 0.2 solid lighten($c-main, 10%);
//         background-color: $c-main;
//         transform: scale(1.3);
//     }
// }

// .select {
//     display: inline-block;
//     height: $base * 1.3;
//     width: $base * 1.3;
//     border-radius: 50%;
//     border: 2px solid $c-red;
//     &--active {
//         background-color: $c-green;
//         border-color: $c-green;
//     }
//     &--inactive {
//         background-color: $c-cloud;
//         border-color: $c-cloud;
//     }
// }

// .progress {
//     display: inline-block;
//     height: $base * 1.5;
//     width: $base * 1;
//     background-color: $c-cloud;
//     border-radius: $base * 0.3;
//     margin-right: $base * 0.5;
//     &--s {
//         height: $base * 1;
//         width: $base * 0.7;
//     }
// }

// .on {
//     display: inline-block;
//     height: $base * 1;
//     width: $base * 1;
//     border-radius: 50%;
//     background-color: $c-green;
// }
// .line {
//     height: $base * 0.6;
//     background-color: $c-main; // #1d93f3;
//     &:first-child {
//         border-top-left-radius: $base * 0.3;
//     }
//     &:nth-child(2n) {
//         // background-color: #ff9131;
//         background-color: #f60f57;
//     }
//     &:nth-child(3n) {
//         background-color: #29df73;
//     }
//     &:nth-child(4n) {
//         background-color: #ffd901;
//     }
//     &:last-child {
//         border-top-right-radius: $base * 0.3;
//     }
// }
