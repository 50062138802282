.w-screen {
    width: 100vw;
    overflow-x: hidden;
}

.min-w-screen {
    min-width: 100vw;
}

.min-w-10 {
    min-width: $base * 25;
}

.max-w-lg {
    max-width: 34rem;
}

.max-w-xl {
    max-width: 40rem;
}

.w-full {
    width: 100%;
}

.w-0 {
    width: 0;
}
.w-1 {
    width: 0.25rem;
}
.w-2 {
    width: 0.5rem;
}
.w-3 {
    width: 0.75rem;
}
.w-4 {
    width: 1rem;
}

.w-5 {
    width: 1.25rem;
}

.w-6 {
    width: 1.5rem;
}
.w-7 {
    width: 1.75rem;
}
.w-10 {
    width: 2.25rem;
}

.w-12 {
    width: 3rem;
}

.w-16 {
    width: 4rem;
}
