.bg-white {
    background-color: $c-white;
}

.bg-gray {
    background-color: #f1f3f5;
}

.bg-main {
    background-color: $c-1;
}
