.link {
    position: relative;
    display: flex;
    padding: $base;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: $base * 1.2;
    height: 100%;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
    &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -2px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: $c-1;
        transform: scaleX(0);
        transition: $transition-hover;
    }
}

.link-active {
    color: $c-1;
    &:after {
        transform: scaleX(1);
    }
}
