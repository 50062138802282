.status {
    position: relative;
    height: $base * 0.8;
    border-radius: $base * 0.8;
    width: 100%;
    background-color: $c-border;
}

.status-inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: $base * 0.5;
    background-color: $c-main;
}

.status-wrap {
    padding-left: $base * 1;
    border-left: $base * 0.5 solid $c-orange;
    border-radius: $base * 0.5;
}

.wrap-question {
    border-color: $c-green;
}
