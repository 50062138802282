.mt-min {
    position: relative;
    top: -4px;
}

.mt {
    margin-top: $base;
}

.mt-s {
    margin-top: $base * 0.5;
}

.mt-m {
    margin-top: $base * 2;
}

.mt-xl {
    margin-top: $base * 4;
}

.mt-form {
    margin-top: 12vh;
}

.ml {
    margin-left: $base;
}

.ml-min {
    margin-right: -$base;
}

.ml-s {
    margin-left: $base * 0.5;
}

.mr {
    margin-right: $base;
}

.mr-m {
    margin-right: $base * 5;
}

.mr-s {
    margin-right: $base * 0.5;
}

.mb {
    margin-bottom: $base;
}

.mb-m {
    margin-bottom: $base * 2;
}

.mb-s {
    margin-bottom: $base * 0.5;
}

.mb-mobile {
    margin-bottom: $base * 2;
    @include mq(laptop) {
        margin-bottom: 0;
    }
}

.mt-mobile {
    margin-top: $base * 2;
    @include mq(laptop) {
        margin-top: 0;
    }
}

.mt-mobile-s {
    margin-top: $base * 1;
    @include mq(laptop) {
        margin-top: 0;
    }
}
