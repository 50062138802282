.card {
    position: relative;
    display: block;
    width: 100%;
    border-radius: $radius;
    background: $c-white;
    padding: $base;
    // box-shadow: $shadow;
    // border: 2px solid $c-white;
}

.card-padding {
    display: block;
    padding: $base * 1;
    @include mq(laptop) {
        padding: $base * 1.5 $base * 2;
    }
}

.card-label {
    padding: $base * 0.5 $base;
    background-color: rgba($c-main, 0.2);
    color: $c-main;
    border-radius: $base * 0.5;
}

.is-card-owner {
    border: 2px solid $c-main;
}

.is-card-supervisor {
    border: 2px solid $c-red;
}

.is-card-member {
    border: 2px solid $c-yellow;
}

.card-timeline {
    display: block;
    border-radius: $base * 0.5;
    background: $c-white;
    // box-shadow: $shadow;
    margin-left: $base * 2.2;
    margin-top: $base * 1;
    // padding: $base;
    // border: 2px solid $c-main;
}

// @keyframes pulse_icon {
//     0% {
//         opacity: 0.4;
//     }
//     50% {
//         opacity: 0;
//         transform: scale(1.8);
//     }
//     100% {
//         opacity: 0;
//     }
// }

// .icon-wrap {
//     position: relative;
//     background-color: $c-white;
//     border-radius: 50%;
//     &:before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 100%;
//         border-radius: 50%;
//         background-color: $c-white;
//         animation: pulse_icon 2000ms infinite linear;
//     }
// }

// .card-drag {
//     background-color: lighten($c-border, 0%);
//     // border-left: $base * 0.5 solid darken($c-border, 15%);
// }

// .card-no-drag {
//     background-color: lighten($c-red, 0%);
//     border-left: $base * 0.5 solid darken($c-red, 15%);
// }

// .card-confirm {
//     width: 20rem;
//     border-radius: $base * 0.5;
//     box-shadow: $shadow;
// }

// .card-hover {
//     &:hover {
//         border: 2px solid $c-main;
//     }
// }
