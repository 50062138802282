.flex {
    display: flex;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-align {
    align-items: center;
}

.flex-space {
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-middle {
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-reverse {
    flex-direction: row-reverse;
}

.flex-push {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.flex-nav {
    display: block;
    @include mq(laptop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
