.auth_layout {
    min-height: calc(100vh - 8rem);
    background-color: $c-white;
    width: 100%;
    border-top-left-radius: $base * 4;
    border-top-right-radius: $base * 4;
    border-top: 2px solid $c-border;
    @include mq(laptop) {
        border-radius: 0;
        border-color: $c-white;
    }
}

.auth_social_label {
    position: absolute;
    display: inline-flex;
    left: 50%;
    top: 15%;
    padding: $base * 1;
    background-color: $c-white;
    transform: translateX(-50%);
}

.auth_header {
    height: $base * 10;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq(laptop) {
        background-color: $c-white;
    }
}

.auth_nav {
    height: $base * 10;
    @include mq(laptop) {
        height: $base * 5;
    }
}

.auth_login_wrap {
    width: 100%;
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(5, 0px);
        flex-basis: grid(5, 0px);
    }
}

.auth_img_wrap {
    display: none;
    // background-color: #eeeeff;
    @include mq(laptop) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        max-width: grid(7, 0px);
        flex-basis: grid(7, 0px);
    }
}

.auth__img {
    width: 100%;
    max-width: 35rem;
}
