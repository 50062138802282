.cell-container {
    overflow: auto;
    width: 100%;
    white-space: nowrap;
}

.cell {
    display: inline-flex;
    align-items: center;
    height: $base * 4;
    padding-right: $base;
    border-bottom: 1px solid $c-cloud;
    border-right: 1px solid $c-cloud;
}

.cell-1 {
    width: $base * 5;
}

.cell-2 {
    width: $base * 20;
}
