.c-main {
    color: $c-main;
}

.c-sec {
    color: $c-gray-d;
}

.c-w {
    color: $c-white;
}

.c-cloud {
    color: $c-cloud;
}

.c-g {
    color: $c-border;
}

.c-o {
    color: $c-orange;
}

.fill-y {
    fill: $c-yellow;
}

.fill-o {
    fill: $c-orange;
}

.fill-g {
    fill: $c-green;
}

.fill-gr {
    fill: $c-border;
}

.c-o-fill-half {
    fill: url(#half);
}
