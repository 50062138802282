// .timeline {
//     position: relative;
//     min-height: 30vh;
//     margin-left: 0;
//     margin-top: $base * 2;
//     margin-bottom: $base * 2;
//     // padding-top: $base;
//     padding-bottom: $base;
//     &::before {
//         content: "";
//         position: absolute;
//         left: 0;
//         top: 0;
//         bottom: 0;
//         width: $base * 1;
//         border-radius: $base * 1;
//         background-color: $c-border;
//         // transform: translateX(-50%);
//     }
// }

// .timeline-item {
//     position: relative;
//     // margin-left: $base * 2;
//     padding: $base;
//     border: 2px solid $c-border;
//     border-radius: $base * 0.5;
//     margin-bottom: $base * 2;

//     // &::before {
//     //     content: "";
//     //     position: absolute;
//     //     top: 0;
//     //     left: -$base * 2.5;
//     //     height: $base * 1.5;
//     //     width: $base * 1.5;
//     //     background-color: lighten($c-orange, 10%);
//     //     border: 2px solid $c-orange;
//     //     border-radius: $base * 1;
//     // }
// }

.tl-line {
    margin-top: $base * 0.3;
    margin-bottom: $base * 0.3;
    margin-left: $base * 0.6;
    height: $base * 1;
    width: $base * 0.2;
    border-radius: $base * 2;
    background-color: $c-main;
}

.tl-question {
    background-color: $c-main;
}
