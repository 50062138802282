.b {
	border: 2px solid $c-border;
}

.bb {
	border-bottom: 2px solid $c-border;
}

.bt {
	border-top: 2px solid $c-border;
}

.bl {
	border-left: 2px solid $c-border;
}

.br {
	border-right: 2px solid $c-border;
}

.b-b {
	border: 2px solid $c-main;
}

.b-re {
	border: 2px solid $c-red;
}

.b-o {
	border: 2px solid $c-orange;
}

.outline {
	background-color: rgba($c-main, 0.2);
}

.b-main {
	border: 2px solid $c-1;
}

.b-dashed-main {
	border: 2px dashed $c-1;
}

.bb-main {
	border-bottom: 2px solid $c-1;
}
