.row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.col-12 {
    flex-grow: 0;
    max-width: grid(12);
    flex-basis: grid(12);
}

.col-8 {
    flex-grow: 0;
    max-width: grid(8);
    flex-basis: grid(8);
}

.col-6 {
    flex-grow: 0;
    max-width: grid(6);
    flex-basis: grid(6);
}

.col-4 {
    flex-grow: 0;
    max-width: grid(4);
    flex-basis: grid(4);
}

.col-3 {
    flex-grow: 0;
    max-width: grid(3);
    flex-basis: grid(3);
}

.col-4-no-gap {
    flex-grow: 0;
    max-width: grid(6, 0rem);
    flex-basis: grid(6, 0rem);
}

.col-5 {
    padding: $base * 2 0;
    flex-grow: 0;
    max-width: grid(5, 0rem);
    flex-basis: grid(5, 0rem);
}

.col-2 {
    flex-grow: 0;
    max-width: grid(2, 0rem);
    flex-basis: grid(2, 0rem);
}

.col-8-no-gap {
    flex-grow: 0;
    max-width: grid(8, 0rem);
    flex-basis: grid(8, 0rem);
}

/*
*  TABLET
*/

.col_s__4 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_s__6 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.col_s__8 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(8);
        flex-basis: grid(8);
    }
}

/*
*  LAPTOP
*/

.col-m-3 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(3);
        flex-basis: grid(3);
    }
}

.col-m-4 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col-m-6 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.col-m-8 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(8);
        flex-basis: grid(8);
    }
}

/*
*  DESKTOP
*/

.col-l-4 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col-l-3 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(3);
        flex-basis: grid(3);
    }
}

.col_l-6 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.mb-off {
    @include mq(mobile) {
        display: none;
    }
}

.lap-off {
    @include mq(laptop) {
        display: none;
    }
}
