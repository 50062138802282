input,
textarea,
select {
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	font-size: inherit;
	font-family: inherit;
	overflow: visible;
	line-height: inherit;
	box-shadow: none;
	&:focus,
	&:active {
		outline: none;
	}
}

textarea {
	resize: vertical;
}

.input {
	width: 100%;
	padding: $base * 0.9 $base;
	color: $c-text;

	background-color: $c-white;
	border: 2px solid $c-border;
	border-radius: $radius;
	font-size: $base * 1.2;
	&::placeholder {
		color: $c-text-gray;
	}
	&:focus {
		border-color: $c-1;
	}
	&--error {
		border-color: $c-red !important;
	}
}

.input-error {
	height: $base * 5;
}

.input-edit {
	&:hover {
		background-color: rgba($c-gray, 0.3);
		svg {
			opacity: 1;
		}
	}
}

.input-edit-form {
	background-color: rgba($c-gray, 0.3);
}

.edit {
	&:hover {
		background-color: rgba($c-gray, 0.3);
	}
}

select {
	// A reset of styles, including removing the default dropdown arrow
	appearance: none;
	// Additional resets for further consistency
	background-color: transparent;
	border: none;

	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
}
