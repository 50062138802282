h1 {
	line-height: 1.2em;
}

.center {
	text-align: center;
}

.fw-b {
	font-weight: 600;
}

.fw-n {
	font-weight: 400;
}

.a-l {
	text-align: left;
}

.a-r {
	text-align: right;
}

.fs-xxs {
	font-size: $base * 0.8;
}

.fs-xs {
	font-size: $base * 1;
}

.fs-s {
	font-size: $base * 1.1;
}

.fs-l {
	font-size: $base * 1.8;
}

.tu {
	text-transform: uppercase;
}

.ts {
	text-decoration: line-through;
}

.tc {
	text-transform: lowercase;
}

.underline {
	text-decoration: underline;
}

.text-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.up {
	position: relative;
	top: -2px;
}

.p {
	font-weight: 400;
	line-height: 1.4;
	font-size: $base * 1.2;
	width: 100%;
	white-space: pre-wrap;

	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.italic {
	font-style: italic;
}
