button {
	writing-mode: none !important;
	border: none;
	&:disabled {
		cursor: not-allowed;
		pointer-events: all !important;
	}
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: $base * 0.3;
	height: $base * 2.6;
	padding: 0 $base * 0.5;
	background-color: $c-main;
	border: 2px solid darken($c-main, 5%);
	color: $c-white;
	font-size: $base * 1.1;
	&:hover {
		background-color: darken($c-main, 15%);
		border: 2px solid darken($c-main, 10%);
	}
	&--gray {
		background-color: $c-border;
		border: 2px solid darken($c-border, 5%);
		color: darken($c-border, 25%);
		&:hover {
			background-color: darken($c-gray-l, 5%);
			border: 2px solid darken($c-gray-l, 10%);
		}
	}
	&--disabled {
		background-color: lighten($c-border, 5%);
		border: 2px solid $c-border;
		color: darken($c-border, 10%);
		&:hover {
			background-color: $c-border;
			border: 2px solid darken($c-border, 5%);
		}
	}
	&--orange {
		background-color: lighten($c-orange, 20%);
		border: 2px solid lighten($c-orange, 20%);
		color: darken($c-orange, 15%);
		&:hover {
			background-color: lighten($c-orange, 25%);
			border: 2px solid lighten($c-orange, 25%);
		}
	}
	&--red {
		background-color: $c-red;
		border: 2px solid $c-red;
		color: darken($c-red, 25%);
		&:hover {
			background-color: $c-red;
			border: 2px solid $c-red;
		}
	}
	&--login {
		line-height: $base * 3;
	}
	&--border {
		background-color: $c-white;
		border: 2px solid $c-border;
		color: darken($c-border, 20%);
		&:hover {
			background-color: $c-white;
			border: 2px solid $c-gray-d;
		}
	}
	&--green {
		background-color: $c-green;
		border: 2px solid $c-green;
		color: darken($c-green, 25%);
		&:hover {
			background-color: $c-green;
			border: 2px solid $c-green;
		}
	}
}

.input-flex {
	height: $base * 2.8;
}

.btn-round {
	position: relative;
	height: $base * 4;
	width: $base * 4;
	z-index: 5;
	color: darken($c-main, 20%);
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $c-main;
		border-radius: 50%;
		z-index: -1;
		border: $base * 0.6 solid lighten($c-main, 10%);
		animation: pulse 1600ms infinite $ease-scale;
	}
}

.btn-planning {
	position: relative;
	padding: $base * 2 $base;
	width: 100%;
	border-radius: $radius;

	background-color: $c-white;
	transition: 600ms $ease-card;
	margin-bottom: $base * 2;
	// &:hover {
	//     background-color: lighten($c-border, 5%);
	//     border: 2px solid $c-border;
	// }
}

.btn-yellow {
	background-color: $c-white;
	border: 2px dashed $c-2;
	color: $c-2;
}

.btn-blue {
	background-color: $c-white;
	border: 2px dashed $c-1;
	color: $c-1;
}

.btn-red {
	background-color: $c-white;
	border: 2px dashed $c-3;
	color: $c-3;
}

.btn-purple {
	background-color: $c-white;
	border: 2px dashed $c-4;
	color: $c-4;
}

.p-b-l {
	position: absolute;
	top: $base * 4;
	left: 0;
}

.p-b-r {
	position: absolute;
	bottom: 0;
	right: 0;
}

.p-t-r {
	position: absolute;
	top: 0;
	right: 0;
}

.p-b-r-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

// .btn-sec {
//     border-color: darken($c-cloud, 10%);
//     background-color: $c-white;
//     color: $c-text;
//     &:hover {
//         border-color: darken($c-cloud, 10%);
//         background-color: darken($c-cloud, 10%);
//     }
// }

// .btn-planning {
//     position: relative;
//     height: $base * 4.5;
//     width: $base * 4.5;
//     z-index: 5;
//     &:before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 100%;
//         background-color: $c-main;
//         border-radius: 50%;
//         z-index: -1;
//         box-shadow: $shadow;
//         // border: $base * 0.6 solid lighten($c-main, 10%);
//     }
// }

.btn-close {
	height: $base * 4;
	width: $base * 4;
	border-radius: 50%;
	background-color: $c-main;
}

.user {
	justify-content: middle;
	align-self: center;

	padding: 1px 2px;
	color: lighten($c-main, 10%);
	background-color: rgba($c-main, 0.2);
}
