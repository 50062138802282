.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    height: 2.2rem;
    padding: 0 0.5rem;
    border: 3px solid darken($c-1, 5%);
    color: $c-white;
    font-size: 1rem;
    background-color: $c-1;
    transition: $transition-hover;
    &:hover {
        background-color: darken($c-1, 5%);
    }
}

.button-text {
    background-color: transparent;
    border: 2px solid transparent;
    color: $c-1;
    &:hover {
        background-color: transparent;
    }
}

.button-red {
    background-color: $c-3;
    border: 2px solid darken($c-3, 5%);
    &:hover {
        background-color: $c-3;
    }
}

.button-yellow {
    background-color: $c-2;
    border: 2px solid darken($c-2, 5%);
    &:hover {
        background-color: $c-2;
    }
}

.button-purple {
    background-color: $c-4;
    border: 2px solid darken($c-4, 5%);
    &:hover {
        background-color: $c-4;
    }
}

.button-disabled {
    background-color: lighten($c-1, 30%);
    border: 3px solid lighten($c-1, 25%);
    &:hover {
        background-color: lighten($c-1, 30%);
        border: 3px solid lighten($c-1, 25%);
    }
}

.button-round {
    position: relative;
    height: $base * 4;
    width: $base * 4;
    z-index: 5;
    color: $c-1;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgb($c-1, 0.6);
        border-radius: 50%;
        z-index: -1;
        border: $base * 0.6 solid lighten($c-1, 10%);
        animation: pulse 1600ms infinite $ease-scale;
    }
}

.button-select {
    display: flex;
    align-items: center;
    padding: $base;
    color: $c-white;

    border-radius: $radius;
    background: $c-1;
    border: 2px solid darken($c-1, 5%);
}

.button-border {
    background-color: $c-white;
    color: $c-1;
    border: 2px solid $c-1;
    &:hover {
        background-color: $c-1;
        color: $c-white;
    }
}
