.text-xs {
    font-size: 0.75rem;
}

.text-sm {
    font-size: 0.875rem;
}

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.125rem;
}

.text-xl {
    font-size: 1.25rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.text-6xl {
    font-size: 4rem;
}

.center {
    text-align: center;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.text-uppercase {
    text-transform: uppercase;
}

.display-none {
    display: none;
}
