.tooltip {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: $base * 0.25 $base;

    text-align: center;
    background-color: #26374d;
    border-radius: $base * 0.5;
    // border: 2px solid $c-cloud;
    color: $c-white;
    z-index: 5;
}

// .tool-pulse {
//     position: relative;
//     height: $base * 1;
//     width: $base * 1;
//     border-radius: 50%;

//     &:before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 100%;
//         background-color: $c-main;
//         border-radius: 50%;
//         z-index: -1;
//         border: $base * 0.6 solid lighten($c-main, 10%);
//         animation: pulse 2600ms infinite $ease-scale;
//     }
// }
