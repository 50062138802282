.color-main {
    color: $c-1;
}

.color-white {
    color: $c-white;
}

.color-red {
    color: $c-3;
}

.color-yellow {
    color: $c-2;
}

.color-blue {
    color: $c-1;
}

.color-purple {
    color: $c-4;
}

.color-gray {
    color: $c-gray-d;
}

// .color-white {
//     color: $c-white;
// }

// .color-gray {
//     color: #afafaf;
// }

// .color-4 {
//     color: #1cb0f6;
// }

// .fill {
//     fill: currentColor !important;
// }

// body {
//     font-family: $font-family;
// }

// .color-title {
//     color: #3c3c3c;
// }

// .color-text {
//     color: #777;
// }
