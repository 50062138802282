.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-self-center {
    justify-self: center;
}

.flex-col {
    flex-direction: column;
}

.justify-end {
    justify-content: flex-end;
}
