.navigation {
    position: relative;
    width: 100%;
    z-index: 5;
    background-color: $c-white;
    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    // &--footer {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     height: $base * 5;
    // }
}

.nav-main {
    height: $base * 6;
}

.nav-sub {
    height: $base * 4.5;
    background-color: $c-white;
}

.nav-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.nav-wrap {
    width: 100%;
    // overflow-y: scroll;
}
