.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.top {
    top: 0;
}

.top-100 {
    margin-top: $base * 5;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.bottom {
    bottom: 0;
}
