html {
    color: $c-white;
    min-height: 100%; // [1]
    -webkit-text-size-adjust: 100%; // [2]
    -ms-text-size-adjust: 100%; // [2]
    -moz-osx-font-smoothing: grayscale; // [3]
    -webkit-font-smoothing: antialiased; // [3]
    cursor: default;
    font-style: normal;
}

body {
    font-family: $font-family;
    width: 100%;
    overflow-x: hidden;
    // background-color: $c-white;
    // color: $c-text;
}

/* Hide scrollbar for Chrome, Safari and Opera */
// ::-webkit-scrollbar {
//     display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
