.check-wrap {
    height: $base * 2;
    width: $base * 2;
    // border: 2px solid transparent;
}

.check {
    height: $base * 2;
    width: $base * 2;
    border-radius: $base * 0.5;
    border: 2px solid $c-border;
}

.check-on {
    border: 2px solid $c-border;
    background-color: $c-border;
    svg {
        stroke: $c-gray-d;
    }
}
