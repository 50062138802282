.bg-w {
	background-color: $c-white;
}

.bg-cloud {
	background-color: $c-cloud;
}

.bg-r {
	background-color: rgba($c-red, 0.3);
}

.bg-edit {
	background-color: rgba($c-gray, 0.3);
}

.bg-cloud {
	background-color: darken(#f9fafc, 0.5%);
}

.bgc-main {
	background-color: rgba($c-1, 0.2);
}

.bgc-d {
	background-color: #37517e;
}

.c-d {
	color: #37517e;
}

.bgc-y {
	color: #d3ae31;
	background-color: #ffe280;
}

.bgc-r {
	background-color: #ffb3bc;
	color: #cf041c;
}

.bgc-p {
	background-color: #f8c951;
	color: #d3891a;
}

.bgc-re {
	background-color: #6675ff;
	color: #031599;
}
