.animate-spin {
    animation: spin 1s linear infinite;
    will-change: transform;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.transition-all {
    transition: 300ms $ease-slow;
}
