.border {
    border: 2px solid $c-border;
}

.border-bottom {
    border-bottom: 2px solid $c-border;
}

.border-top {
    border-top: 2px solid $c-border;
}

.radius {
    border-radius: $radius;
}

.border-main {
    border: 2px solid $c-1;
}

.border-yellow {
    border: 2px solid $c-2;
}

.border-red {
    border: 2px solid $c-3;
}
