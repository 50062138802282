.p-wrap-nav,
.p-wrap {
    padding: 0 $base * 2;
    @include mq(laptop) {
        padding: 0 $base * 4;
    }
}

.p-box-s {
    padding: $base * 0.5;
}

.p-box {
    padding: $base * 1;
}

.p-box-l {
    padding: $base * 2;
}

.pt-s {
    padding-top: $base * 0.5;
}

.pt {
    padding-top: $base;
}

.pt-m {
    padding-top: $base * 2;
}

.pt-l {
    padding-top: $base * 3;
}

.pt-xl {
    padding-top: $base * 4;
}

.pl-s {
    padding-left: $base * 0.5;
}
.pl {
    padding-left: $base;
}

.pr-s {
    padding-right: $base * 0.5;
}

.pr {
    padding-right: $base;
}

.pr-m {
    padding-right: $base * 2;
}

.pb-s {
    padding-bottom: $base * 0.5;
}

.pb {
    padding-bottom: $base;
}

.pb-m {
    padding-bottom: $base * 2;
}

.pb-l {
    padding-bottom: $base * 3;
}

.pb-xl {
    padding-bottom: $base * 4;
}
