.container {
    padding-left: $base * 1;
    padding-right: $base * 1;
    width: 100%;
    margin: 0 auto;
    @include mq(laptop) {
        padding-left: $base * 4;
        padding-right: $base * 4;
    }

    @include mq(desktop) {
        max-width: 1380px;
    }

    @include mq(xxl) {
        max-width: 1800px;
    }
}

.container-m {
    padding-top: $base * 2;
    padding-bottom: $base * 2;
    max-width: $base * 40;
    width: 100%;
}

.container-list {
    width: 100%;
    overflow-x: hidden;
}
