.box {
    height: 100vh;
    width: 100vw;
}

.box-login {
    min-height: 100vh;
    width: 100vw;
    &--wrap {
        width: 100%;
        max-width: $base * 35;
    }
}

.box-empty {
    height: calc(100vh - 4.8rem);
}

.box-empty-sub {
    height: calc(100vh - 8.4rem);
}

.box-empty-wrap {
    width: 100%;
    max-width: $base * 50;
}

.box-form-planning {
    width: 100%;
    max-width: $base * 60;
}

// .box-form-planning {
//     width: 100%;
//     max-width: $base * 55;
// }

// .box-library {
//     height: calc(100vh - 10rem);

//     &--wrap {
//         height: 60vh;
//     }
// }

// .box-dash {
//     height: calc(100vh - 14.5rem);
// }

// .box-wrap {
//     width: 100%;
//     max-width: $base * 40;
// }

// .box-messages {
//     width: 100%;
//     max-width: $base * 40;
// }

// .box-scroll {
//     max-height: 50vh;
//     overflow-y: ;
//     -webkit-overflow-scrolling: touch;
//     border: 1px solid $c-cloud;
//     padding: 0 $base;
//     box-shadow: $shadow;
//     border-radius: $base * 0.3;
//     &::-webkit-scrollbar {
//         width: $base;
//     }
//     &::-webkit-scrollbar-track {
//         background-color: $c-cloud;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: $c-silver;
//         border-radius: $base * 0.3;
//     }
// }

// .box-scroll-messages {
//     max-height: 50vh;
//     overflow-y: scroll;
//     -webkit-overflow-scrolling: touch;
//     &::-webkit-scrollbar {
//         width: $base;
//     }
//     &::-webkit-scrollbar-track {
//         background-color: $c-cloud;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: $c-silver;
//         border-radius: $base * 0.3;
//     }
// }

/*
::-webkit-scrollbar {
 	width: em(0);
}
::-webkit-scrollbar-track {
   background-color : transparent;
}
::-webkit-scrollbar-thumb {
	background-color: transparent;
}


*/
