.tag {
    display: inline-flex;
    align-items: center;
    height: $base * 2;
    border-radius: $base * 0.3;
    font-size: $base * 1.2;
    padding: 0 $base * 0.5;
}

.tag-select {
    color: $c-text-gray;
    border: 2px solid $c-border;
}

.tag-active {
    background-color: rgba(71, 163, 255, 0.2);
    color: #47a3ff;
}

.tag-btn {
    height: $base * 2.6;
}
