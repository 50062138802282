.font-normal {
    font-weight: 300;
}

.font-bold {
    font-weight: 600;
}

.font-500 {
    font-weight: 500;
}
