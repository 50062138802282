.icon {
    display: inline-block;
    stroke-width: 3;
    vertical-align: middle;
    fill: none;
}

.logo {
    height: $base * 1.8;
}

.logo-mobile {
    height: $base * 3;
}

.logo-s {
    height: 30px;
}

.logo-m {
    height: $base * 3;
}

.icon-xxs {
    width: $base * 1.1;
    height: $base * 1.1;
}

.icon-xs {
    width: $base * 1.3;
    height: $base * 1.3;
}

.icon-s {
    width: $base * 1.5;
    height: $base * 1.5;
}

.icon-sm {
    width: $base * 1.7;
    height: $base * 1.7;
}

.icon-m {
    width: $base * 3;
    height: $base * 3;
}

.icon-l {
    width: $base * 8;
    height: $base * 8;
}

.icon-progress {
    width: $base * 6;
    height: $base * 6;
}

.spin {
    animation: spin 800ms infinite linear;
}

.pulse {
    animation: pulse 2000ms infinite linear;
}

.pulse-alarm {
    animation: pulse_alarm 2000ms infinite linear;
}

@keyframes pulse_alarm {
    0% {
        box-shadow: 0 0 0 0 rgba($c-red, 0.4);
    }
    50% {
        box-shadow: 0 0 0 $base * 1.5 rgba($c-red, 0);
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($c-red, 0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($c-main, 0.4);
    }
    50% {
        box-shadow: 0 0 0 $base * 1.5 rgba($c-main, 0);
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($c-main, 0);
    }
}

.c-1 {
    fill: #28de74;
}

.c-2 {
    fill: #f60e58;
}

.c-3 {
    fill: #1c93f3;
}

.c-4 {
    fill: #ffd800;
}

.c-5 {
    fill: #1d324a;
}

.cls-1 {
    fill: none;
    clip-rule: evenodd;
}
.cls-2 {
    clip-path: url(#clip-path);
}
.cls-3 {
    fill: #3e82f1;
}
.cls-4 {
    clip-path: url(#clip-path-2);
}
.cls-5 {
    fill: #32a753;
}
.cls-6 {
    clip-path: url(#clip-path-3);
}
.cls-7 {
    fill: #f9bb00;
}
.cls-8 {
    clip-path: url(#clip-path-4);
}
.cls-9 {
    fill: #e74133;
}

.c-f {
    fill: #3c5a9a;
}

.i-f {
    height: $base * 1.3;
}
