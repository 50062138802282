.bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg-tool {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 60%;
}

.bg-loading {
    opacity: 0;
    transition: opacity 1200ms ease-out;
}

.bg-loaded {
    opacity: 1;
}

.img-card {
    height: $base * 16;
}

.img-error {
    height: $base * 23;
}

.img {
    display: inline-flex;
    vertical-align: middle;
    background-color: $c-cloud;
    &--s {
        width: $base * 2.85;
        height: $base * 2.85;
    }
    &--xs {
        width: $base * 2.2;
        height: $base * 2.2;
    }
    &--sm {
        width: $base * 3.7;
        height: $base * 3.7;
    }
    &--m {
        width: $base * 5;
        height: $base * 5;
    }
}

.img__auth {
    max-height: $base * 20;
    max-width: $base * 50;
}
