.full {
    width: 100%;
}

.ov {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.hidden {
    display: none;
}

.inline {
    display: inline-block;
}

.relative {
    position: relative;
}

.b-r {
    border-radius: $base * 0.5;
}

.shadow {
    box-shadow: $shadow;
}

.overflow {
    overflow-x: scroll;
}

.block {
    display: block;
}

.full-mb {
    width: 100%;
    margin-bottom: $base;

    @include mq(laptop) {
        width: auto;
        margin-bottom: 0;
        margin-left: $base;
    }
}
