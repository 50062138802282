.pb-1 {
    padding-bottom: $base * 0.25;
}

.pb-2 {
    padding-bottom: $base * 0.5;
}

.pb-3 {
    padding-bottom: $base * 1;
}

.pb-4 {
    padding-bottom: $base * 1.5;
}

.pb-5 {
    padding-bottom: $base * 2;
}

.pb-6 {
    padding-bottom: $base * 4;
}

.pt-1 {
    padding-top: $base * 0.25;
}

.pt-2 {
    padding-top: $base * 0.5;
}

.pt-3 {
    padding-top: $base * 1;
}

.pt-4 {
    padding-top: $base * 1.5;
}

.pt-5 {
    padding-top: $base * 2;
}

.pt-6 {
    padding-top: $base * 4;
}

.pr-1 {
    padding-right: $base * 0.25;
}

.pr-2 {
    padding-right: $base * 0.5;
}

.pr-3 {
    padding-right: $base * 1;
}

.pr-4 {
    padding-right: $base * 1.5;
}

.pr-5 {
    padding-right: $base * 2;
}

.pl-1 {
    padding-left: $base * 0.25;
}

.pl-2 {
    padding-left: $base * 0.5;
}

.pl-3 {
    padding-left: $base * 1;
}

.pl-4 {
    padding-left: $base * 1.5;
}

.pl-5 {
    padding-left: $base * 2;
}

.p-1 {
    padding: $base * 0.25;
}

.p-2 {
    padding: $base * 0.5;
}

.p-4 {
    padding: $base * 1;
}

.p-5 {
    padding: $base * 1.25;
}
