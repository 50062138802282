.row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.col__12 {
    flex-grow: 0;
    max-width: grid(12);
    flex-basis: grid(12);
}

.col__8 {
    flex-grow: 0;
    max-width: grid(8);
    flex-basis: grid(8);
}

.col__10 {
    flex-grow: 0;
    max-width: grid(10);
    flex-basis: grid(10);
}

.col__6 {
    flex-grow: 0;
    max-width: grid(6);
    flex-basis: grid(6);
}

.col__4 {
    flex-grow: 0;
    max-width: grid(4);
    flex-basis: grid(4);
}

.col__3 {
    flex-grow: 0;
    max-width: grid(3);
    flex-basis: grid(3);
}

.col__6_no_gap {
    flex-grow: 0;
    max-width: grid(6, 0rem);
    flex-basis: grid(6, 0rem);
}

/*
*  TABLET
*/

.col_s__4 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_s__6 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.col_s__8 {
    @include mq(tablet) {
        flex-grow: 0;
        max-width: grid(8);
        flex-basis: grid(8);
    }
}

/*
*  LAPTOP
*/

.col_m__3 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(3, 0.8rem);
        flex-basis: grid(3, 0.8rem);
    }
}

.col_m__4 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_m__6 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.col_m__8 {
    @include mq(laptop) {
        flex-grow: 0;
        max-width: grid(8);
        flex-basis: grid(8);
    }
}

/*
*  DESKTOP
*/

.col_l__4 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(4);
        flex-basis: grid(4);
    }
}

.col_l__3 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(3);
        flex-basis: grid(3);
    }
}

.col_l__6 {
    @include mq(desktop) {
        flex-grow: 0;
        max-width: grid(6);
        flex-basis: grid(6);
    }
}

.mb__off {
    @include mq(mobile) {
        display: none;
    }
}

.lap__off {
    @include mq(laptop) {
        display: none;
    }
}

/*
*  EXTRA LARGE
*/

// .col_xl__3 {
//     @include mq(xl) {
//         flex-grow: 0;
//         max-width: grid(3);
//         flex-basis: grid(3);
//     }
// }

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

// .flex-3 {
//     flex: 3;
// }

// .grid-0 {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     user-select: none;

//     @include mq(m) {
//         width: calc((100% / 2) - 1.6rem);
//         margin-right: $base;
//         &:nth-child(2n) {
//             margin-right: 0;
//         }
//     }
// }
// .grid-1 {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     user-select: none;

//     @include mq(sm) {
//         width: calc((100% / 2) - 1.6rem);
//     }
//     @include mq(m) {
//         width: calc((100% / 3) - 1.6rem);
//         margin-right: $base;
//         &:nth-child(3n) {
//             margin-right: 0;
//         }
//     }
// }

// .grid-2 {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     user-select: none;
//     @include mq(sm) {
//         width: calc((100% / 2) - 1.2rem);
//     }
//     @include mq(m) {
//         width: calc((100% / 4) - 1.4rem);

//         margin-right: $base * 2;
//         &:nth-child(4n) {
//             margin-right: 0;
//         }
//     }
// }

// .grid-3 {
//     display: flex;
//     flex-direction: column;
//     width: 100%;

//     @include mq(sm) {
//         width: calc(100% / 2);
//     }
//     @include mq(m) {
//         width: calc(100% / 4);
//     }
// }
