.layout__login {
    min-height: 100vh;
    width: 100vw;
}

.layout__full {
    height: 100vh;
    width: 100vw;
}

.layout__minus_navigation {
    height: calc(100vh - 4.8rem);
    width: 100vw;
}
