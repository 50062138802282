.menu {
    position: absolute;
    background-color: $c-white;
    min-width: $base * 20;
    border-radius: $radius;
    z-index: 5;
    // box-shadow: $shadow-secondary;
    width: 100%;
    overflow: hidden;
}

.menu-wrap {
    max-height: $base * 35;
    overflow-y: scroll;
}

.menu-apps {
    width: $base * 30;
}

.menu-item {
    padding: $base * 1.2;
    width: 100%;
    text-align: left;
    &:hover {
        background-color: $c-border;
    }
}

.menu-nav {
    padding: $base * 0.5;
    background-color: $c-border;
}

.menu-user {
    padding: $base * 0.25 $base * 0.5;
}

.menu-active {
    background-color: $c-border;
}
