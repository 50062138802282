.file {
	width: 100%;
	padding: $base * 0.5 $base;
	border: 2px solid $c-border;
	border-radius: 0.5 * $base;
	position: relative;

	&::-webkit-scrollbar {
		width: $base !important;
	}
}

.file-inline {
	display: inline-flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding: $base * 0.3 $base * 0.5;
	border: 2px solid $c-border;
	max-width: $base * 15;
}

.file-btn {
	position: absolute;
	top: -$base * 2.2;
	right: -$base;
	background-color: $c-white;
	padding: $base * 0.3 0;
	border: 2px solid $c-gray-d;
}

.files-container {
	padding: 0 $base 0 0;
	max-height: $base * 8;
	min-height: $base * 8;
	overflow-y: scroll;
	@include mq(laptop) {
		max-height: $base * 16;
		min-height: $base * 16;
	}
	&::-webkit-scrollbar {
		width: $base !important;
	}
	&::-webkit-scrollbar-track {
		border-radius: $base * 0.5;
		background-color: rgba($c-1, 0.15);
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba($c-1, 1);
		border-radius: $base * 0.5;
	}
}
